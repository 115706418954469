@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
// @import '_foundation-icons';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;
// @include foundation-prototype-shadow;

@include motion-ui-transitions;
@include motion-ui-animations;

// Functions & Mixins
// Replace string with string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
  
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
  
	@return $string;
}

@mixin icon_dropshadow($ds_color) {
  -webkit-filter: drop-shadow(0px 0px 5px $ds_color); /* Safari */
	filter: drop-shadow(0px 0px 5px $ds_color);
}

.top-logo {
	img {
		width: 100%;
		max-width: 600px;
		@include icon_dropshadow($big-groen);
	}
}

.code-input {
	input {
		text-transform: uppercase;
		text-align: center;
		&:readonly {
			color: $big-rood;
		}
	}
}

.code-return {
	color: $big-rood;
}

.solved h1{
	color: $white;
	text-align: center;
}

.hint-currency {
	font-size: 120%;
	img {
		// height: 60%;
		width: 20%;
		padding: 5px;
	}
}

#nextLevelModal img, #decryptingModal img {
	width: 100%;
}

body.accessdenied:before {
	content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
	background-color: darken($big-rood, 20%);
	-webkit-animation: flash linear 1s infinite;
	animation: flash linear 1s infinite;
}

body.accessgranted:before {
	content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
	background-color: darken($big-groen, 20%);
	-webkit-animation: flash linear 1s infinite;
	animation: flash linear 1s infinite;
}

/* LABYRINTH STYLES */
#lab-center {
	font-size: 4.8rem;
	// padding: .85em 1em;
	img {
		margin: 0 0 1rem 0;
	}
}

/* Flash class and keyframe animation */
.flash{
	-webkit-animation: flash linear 1s infinite;
	animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}

/* clock */

video {
	width: 100%;
}

.clock {
  color: white;
  font-size: 4rem;
  font-family: sans-serif;
  /*background: green;*/
  overflow: hidden;
	.coins_saldo {
		color: white;
		font-size: 2.5rem;
		img {
			height: 2rem;
		}
	}
	
}

.greenflash {
	background-color: darken($big-groen, 20%);
	-webkit-animation: flash linear 0.6s infinite;
	animation: flash linear 0.6s infinite;
}

.logo_staging {
	height: 80vh;
	@include icon_dropshadow($big-groen);
	-webkit-animation: spinningLogo linear 8s infinite;
	animation: spinningLogo linear 8s infinite;
}

@-webkit-keyframes spinningLogo {
	0% { -webkit-transform: rotateY(0deg); }
	50% { -webkit-transform: rotateY(360deg); }
	100% { -webkit-transform: rotateY(0deg); }
}

@keyframes spinningLogo {
	0% { transform: rotateY(0deg); }
	50% { transform: rotateY(360deg); }
	100% { transform: rotateY(0deg); }
}

.bombdisabled {
  color: $big-groen;
  font-size: 2.5rem;
  font-family: sans-serif;
  /*background: green;*/
  overflow: hidden;
	.coins_saldo {
		color: white;
		img {
			height: 2rem;
		}
	}
}

.exploded {
  color: red;
}

.sf_input[type="date"] {
	font-size: 1rem;
	padding: 0rem;
	height: 3rem;
}

select.sf_input {
	padding: 0rem;
}

.sf_input {
	font-size: 1rem;
	// padding: 1rem;
	height: 3rem;
}

.cb_start {
	color: $white;
}